<template>
  <div>
    <div style="margin-bottom: -20px;;">
      <b-card class="bg-light">
        <b-row>
          <b-col md="6">
            <h5 class="text-primary"><strong>Pemeliharaan Rutin Jalan</strong></h5>
          </b-col>
          <b-col md="3">
            <h5 class="text-primary"><strong>{{ items.pemeliharaan_rutin.total_rutin_km }} <small>KM</small></strong>
            </h5>
          </b-col>
          <b-col md="3">
            <h5 class="text-primary"><sup>Rp. </sup><strong style="float: right;">{{
              Rp(items.pemeliharaan_rutin.total_rutin) }},-</strong>
            </h5>
          </b-col>
        </b-row>
        <b-alert variant="primary" show>
          <div class="alert-body">
            <b-row>
              <b-col md="3">
                <h6 class="text-primary"> <small>Pemeliharaan Rutin Aspal</small><br>
                  {{ items.pemeliharaan_rutin.pajang_pr_aspal }} KM
                  <strong style="float: right;"> <Sup>Rp. </Sup> {{ Rp(items.pemeliharaan_rutin.pr_aspal) }},-</strong>
                </h6>
              </b-col>
              <b-col md="3">
                <h6 class="text-primary"> <small>Pemeliharaan Rutin Kondisi Aspal</small><br>
                  {{ items.pemeliharaan_rutin.pajang_rk_aspal }} KM
                  <strong style="float: right;"> <Sup>Rp. </Sup> {{ Rp(items.pemeliharaan_rutin.rk_aspal) }},-</strong>
                </h6>
              </b-col>
              <b-col md="3">
                <h6 class="text-primary"> <small>Holding Aspal</small><br>
                  {{ items.pemeliharaan_rutin.pajang_holding_aspal }} KM
                  <strong style="float: right;"> <Sup>Rp. </Sup> {{ Rp(items.pemeliharaan_rutin.rk_aspal) }},-</strong>
                </h6>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col md="3">
                <h6 class="text-primary"> <small>Pemeliharaan Rutin Beton</small><br>
                  {{ items.pemeliharaan_rutin.pajang_pr_beton }} KM
                  <strong style="float: right;"> <Sup>Rp. </Sup> {{ Rp(items.pemeliharaan_rutin.pr_beton) }},-</strong>
                </h6>
              </b-col>
              <b-col md="3">
                <h6 class="text-primary"> <small>Pemeliharaan Rutin Kondisi Beton</small><br>
                  {{ items.pemeliharaan_rutin.pajang_beton }} KM
                  <strong style="float: right;"> <Sup>Rp. </Sup> {{ Rp(items.pemeliharaan_rutin.rk_beton) }},-</strong>
                </h6>
              </b-col>
              <b-col md="3">
                <h6 class="text-primary"> <small>Holding Beton</small><br>
                  {{ items.pemeliharaan_rutin.pajang_holding_beton }} KM
                  <strong style="float: right;"> <Sup>Rp. </Sup> {{ Rp(items.pemeliharaan_rutin.rk_beton) }},-</strong>
                </h6>
              </b-col>
            </b-row>
          </div>
        </b-alert>
      </b-card>
    </div>
    <div style="margin-bottom: -20px;;" v-for="pekerjaan in items.pekerjaan" v-bind:key="pekerjaan">
      <b-card class="bg-light" v-show="pekerjaan.pekerjaan.pagu_usulan != null">
        <b-row>
          <b-col md="6">
            <h6 class="text-primary"><strong>{{ pekerjaan.nama_pekerjaan }}</strong></h6>
          </b-col>
          <b-col md="3">
            <h6 class="text-primary"><strong>{{ pekerjaan.pekerjaan.panjang_ruas }} <small>KM</small></strong></h6>
          </b-col>
          <b-col md="3">
            <h6 class="text-primary"><sup>Rp. </sup><strong style="float: right;">{{
              Rp(pekerjaan.pekerjaan.pagu_usulan) }},-</strong>
            </h6>
          </b-col>
        </b-row>
        <b-alert variant="warning" show v-show="pekerjaan.pekerjaan.rincian != ''">
          <div class="alert-body">
            <b-row v-for="rincian in pekerjaan.pekerjaan.rincian" v-bind:key="rincian">
              <b-col md="6">
                <h6 class="text-primary"> <small><strong>Nama Pekerjaan : {{ rincian.nama_pekerjaan }}</strong> <br>Ruas
                    Jalan {{
                      rincian.no_ruas_jalan }} - {{ rincian.nama_ruas_jalan }}</small></h6>
              </b-col>
              <b-col md="3">
                <h6 class="text-primary"> <small>{{ rincian.panjang }} KM</small>
                </h6>
              </b-col>
              <b-col md="3">
                <h6 class="text-primary"><sup>Rp. </sup><strong style="float: right;">{{
                  Rp(rincian.pagu_usulan) }},-</strong>
                </h6>
              </b-col>
            </b-row>
          </div>
        </b-alert>
      </b-card>
    </div>
  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BRow, BCol, BCard, BAlert
} from 'bootstrap-vue'
export default {
  components: {
    BTabs,
    BTab, BRow, BCol, BCard, BAlert
  },
  props: {
    items: {}
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>